import NavBar from '../NavBar/navBar';
import './Home.css';

function Home() {
  return (
    <div className="App">
      <NavBar/>
    </div>
  );
}

export default Home;
